import React, { ReactElement, ReactNode } from "react";
import Head from "next/head";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { setVisitorInfo } from "@lib/severalBrandsHelpers/helpers";
import { getUserSession } from "src/api/severalBrandsSession";
import SeveralBrandsHeader from "@components/severalbrands/header";
import SeveralBrandsFooter from "@components/severalbrands/footer";
import { DomainInfo } from "@components/severalbrands/utils/services";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function SeveralBrandsWrapper({
    children,
    domainInfo,
    hideHeader = false,
    contentListExist = false,
}: {
    children: ReactNode;
    domainInfo: DomainInfo;
    hideHeader?: boolean;
    contentListExist?: boolean;
}): ReactElement {
    const router = useRouter();

    const fetchData = async () => {
        const data = await getUserSession(
            window.location.href,
            document.referrer,
        );
        if (data && data.data) {
            //@ts-ignore
            setVisitorInfo(data.data);
        }
    };
    useEffect(() => {
        fetchData().catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
        });
    }, [router.asPath]);
    useEffect(() => {
        const targetId = window.location.hash.substring(1);
        if (targetId === "testimonials") {
            const targetElement = document.getElementById("testimonials-1");
            if (targetElement) {
                setTimeout(() => {
                    targetElement.scrollIntoView({
                        behavior: "smooth",
                    });
                }, 0);
            }
        }
    }, []);

    return (
        <>
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />

                <link
                    href="https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap"
                    rel="stylesheet"
                />
            </Head>
            <style jsx global>{`
                body {
                    overflow-x: unset;
                }
            `}</style>
            <GoogleReCaptchaProvider
                reCaptchaKey={
                    domainInfo?.recaptchaConfig?.key ??
                    (process.env
                        .NEXT_PUBLIC_SEVERAL_BRANDS_GOOGLE_RECAPTCHA_SITE_KEY as string)
                }
            >
                <div style={{ fontFamily: `'Lexend Deca', sans-serif` }}>
                    {!hideHeader && <SeveralBrandsHeader domain={domainInfo} />}

                    <main className="max-w-7xl 3xl:max-w-[120rem] mx-auto block px-4vw lg:px-4">
                        {children}
                    </main>

                    <SeveralBrandsFooter
                        domain={domainInfo}
                        contentListExist={contentListExist}
                    />
                </div>
            </GoogleReCaptchaProvider>
        </>
    );
}
