import React from "react";

function LinkedinIcon() {
    return (
        <svg
            width="133"
            height="42"
            viewBox="0 0 133 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="133" height="42" rx="10" fill="#37367D" />
            <g clipPath="url(#clip0_1561_7756)">
                <path
                    d="M86.4766 12.0966C86.4766 11.2237 87.2088 10.5157 88.112 10.5157H106.972C107.876 10.5157 108.608 11.2237 108.608 12.0966V31.005C108.608 31.8782 107.876 32.5859 106.972 32.5859H88.112C87.2088 32.5859 86.4766 31.8782 86.4766 31.005V12.0966Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.5234 29.002H33.7595V25.9972H27.8659V14.5331H24.5234V29.002Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M38.4349 29.002V19.0363H35.0922V29.002H38.4349ZM36.7635 17.6756C37.9292 17.6756 38.6547 16.9103 38.6547 15.9534C38.633 14.9758 37.9292 14.232 36.7857 14.232C35.6421 14.232 34.8945 14.9758 34.8945 15.9534C34.8945 16.9103 35.6199 17.6756 36.7417 17.6756H36.7635Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.9883 29.002H43.331V23.4365C43.331 23.1387 43.3527 22.8411 43.4412 22.6282C43.6827 22.0334 44.2326 21.417 45.1561 21.417C46.3658 21.417 46.8497 22.3312 46.8497 23.6701V29.002H50.1921V23.2876C50.1921 20.2265 48.5428 18.8021 46.3436 18.8021C44.5404 18.8021 43.7486 19.801 43.3088 20.4813H43.3311V19.0363H39.9883C40.0322 19.9714 39.9883 29.002 39.9883 29.002Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M54.9054 14.5331H51.5625V29.002H54.9054V25.771L55.7408 24.7288L58.3578 29.002H62.4699L58.072 22.8158L61.9204 18.6069H57.8961C57.8961 18.6069 55.1473 22.3725 54.9054 22.819V14.5331Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M71.4128 24.8456C71.4567 24.5896 71.5228 24.0986 71.5228 23.5436C71.5228 20.9673 70.2031 18.3521 66.7287 18.3521C63.0121 18.3521 61.2969 21.2648 61.2969 23.9066C61.2969 27.1741 63.386 29.2148 67.0365 29.2148C68.488 29.2148 69.8292 29.0025 70.9289 28.556L70.4889 26.3684C69.5874 26.6648 68.6638 26.8129 67.5204 26.8129C65.959 26.8129 64.5957 26.1779 64.4856 24.8243L71.4128 24.845V24.8456ZM64.4638 22.6046C64.5517 21.7541 65.1235 20.4996 66.5529 20.4996C68.0699 20.4996 68.422 21.8393 68.422 22.6046H64.4638Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M79.7713 14.5336V19.5423H79.7274C79.2435 18.8413 78.2317 18.3736 76.8907 18.3736C74.3175 18.3736 72.0526 20.4143 72.0744 23.9003C72.0744 27.1319 74.1196 29.2363 76.6705 29.2363C78.0559 29.2363 79.3753 28.6409 80.0351 27.4929H80.1012L80.2331 29.0025H83.2021C83.1581 28.3009 83.1137 27.0891 83.1137 25.8989V14.5336H79.7713ZM79.7713 24.3896C79.7713 24.6444 79.7493 24.8998 79.7052 25.1121C79.5076 26.0263 78.7156 26.6639 77.7483 26.6639C76.3628 26.6639 75.4611 25.5586 75.4611 23.8154C75.4611 22.1787 76.231 20.8608 77.7701 20.8608C78.8035 20.8608 79.5294 21.5621 79.7274 22.4335C79.7713 22.6252 79.7713 22.8378 79.7713 23.0289V24.3896Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M93.1849 28.9902V19.0243H89.8421V28.9902H93.1849ZM91.5135 17.6636C92.6792 17.6636 93.4047 16.8983 93.4047 15.9419C93.383 14.964 92.6792 14.22 91.5356 14.22C90.3922 14.22 89.6445 14.964 89.6445 15.9419C89.6445 16.8983 90.3699 17.6636 91.4917 17.6636H91.5135Z"
                    fill="#37367D"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M95.0352 28.9902H98.3779V23.4248C98.3779 23.127 98.3996 22.8294 98.4879 22.6165C98.7295 22.0214 99.2795 21.405 100.203 21.405C101.413 21.405 101.896 22.3189 101.896 23.6587V28.9902H105.239V23.2759C105.239 20.2148 103.59 18.7904 101.39 18.7904C99.5871 18.7904 98.7955 19.7892 98.3556 20.4696H98.378V19.0243H95.0352C95.0791 19.9594 95.0352 28.9902 95.0352 28.9902Z"
                    fill="#37367D"
                />
            </g>
            <defs>
                <clipPath id="clip0_1561_7756">
                    <rect
                        width="85"
                        height="23"
                        fill="white"
                        transform="translate(24 10)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default LinkedinIcon;
