import React, { ReactElement, useEffect, useState } from "react";
import SeveralBrandsLogo from "../logo";
import styles from "./style.module.scss";
import Burger from "./burger";
import { DomainInfo } from "@components/severalbrands/utils/services";
import useWindowSize from "@hooks/useWindowSize";

export default function SeveralBrandsHeader({
    domain,
}: {
    domain: DomainInfo;
}): ReactElement {
    const [isOpened, setOpened] = useState(false);
    const windowSize = useWindowSize();
    const listPages = domain?.menus
        ? domain?.menus.find((menu) => menu.slug === "severalbrands-header")
              ?.links
        : domain.menusList?.find((menu) => menu.slug === "severalbrands-header")
              ?.items;

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (windowSize.width < 767) {
                if (window.scrollY >= 15) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            } else {
                if (window.scrollY >= 83) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [windowSize]);
    return (
        <header
            id="headerId"
            className={`md:mt-[60px] mt-[15px]  px-4 z-100  ${
                styles["stickyStyle"]
            } ${isSticky ? styles["stickyStyleAfter"] : ""}`}
        >
            <div className=" flex lg:max-w-7xl 3xl:max-w-[120rem] mx-auto justify-between   relative  ">
                <div
                    className="logo"
                    style={{
                        backgroundImage: `url(${domain?.logo?.fullPath ?? ""})`,
                        width: "auto",
                        height: "auto",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                >
                    <a href="/" aria-label={`${domain?.name} logo`}>
                        {domain?.logo?.fullPath ? (
                            <img
                                src={domain?.logo?.fullPath}
                                className="w-full h-auto lg:h-10 3xl:h-12"
                                alt={`${domain?.name} logo`}
                                style={{ visibility: "hidden" }}
                            />
                        ) : (
                            <span className="children:w-full children:h-auto lg:children:h-10 3xl:children:h-12">
                                <SeveralBrandsLogo />
                            </span>
                        )}
                    </a>
                </div>
                <div
                    className={`${styles["burger"]} ${
                        isOpened ? styles["openBurger"] : ""
                    }`}
                >
                    <Burger
                        isOpened={isOpened}
                        setOpened={(x) => {
                            setOpened(x);
                        }}
                    />
                </div>
                <div
                    className={
                        isOpened
                            ? `${styles["menu"]} ${styles["open"]}`
                            : styles["menuItem"]
                    }
                >
                    <div className={styles["innerDiv"]}>
                        <h3>Menu</h3>
                        <div className={styles["allMenu"]}>
                            {listPages?.map((page) => (
                                <span
                                    key={page.link}
                                    className="block my-3  w-fit hover:underline mx-auto md:mx-0"
                                >
                                    <a href={page.link}>{page.title}</a>
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
