import * as React from "react";

const YoutubeIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        fill="none"
        viewBox="0 0 42 42"
    >
        <rect width="42" height="42" fill="#37367D" rx="10"></rect>
        <path
            fill="#fff"
            d="M18.152 14.31c-1.375-.847-3.152.144-3.152 1.753v10.872c0 1.618 1.777 2.6 3.151 1.755l8.869-5.44a2.053 2.053 0 0 0 0-3.5z"
        ></path>
    </svg>
);

export default YoutubeIcon;
